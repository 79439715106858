  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/2-2-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li>L'expert d'assuré</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Qu’est ce qu’un expert d’assuré ?  </h1>

<p>L’expert d’assuré est un expert professionnel qui peut être sollicité par toute personne physique ou morale (particulier ou entreprise) dans le cadre d’un sinistre pour contre expertiser l’analyse de l’expert de la partie adverse afin de réévaluer votre indemnisation.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="L'expert d'assuré" />
</div>


<p><strong>L’Expert d’assuré défend uniquement vos intérêts</strong> </p>

<p>Par exemple, votre logement a subi une inondation. Vous n’êtes pas satisfait du montant indemnitaire proposé par votre assurance. L’Expert d’assuré RV EXPERTISES va permettre d’une part d'accélérer le traitement de votre dossier et d’autre part de négocier le montant indemnitaire afin d’obtenir une indemnisation à la juste valeur de vos pertes matérielles.</p>

<p>Autre exemple, votre ouvrage est sinistré par des fissures qui sont apparues suite à une période comprenant un Arrêté de CATastrophe NAturelle sur votre commune. </p>
<p>Après le passage de l’Expert d’assurance, ce dernier exclut la sécheresse comme cause déterminante de votre sinistre. Vous êtes par conséquent exclu de garantie.</p>

<p>L’Expert d’assuré prend alors en charge de votre dossier et mène dans un premier temps une expertise unilatérale. En fonction de ses conclusions, il vous assiste lors d’une contre-expertise, qui vous est due par l’Assurance,  afin de vous défendre face à la partie adverse.  </p>

<h2>Que comprend l’intervention de l’Expert d’assuré ?</h2>


<p>L’Expert d’assuré, dès sa nomination par mandat, vous assiste dans la détermination de la mission d’expertise par étude préalable de votre dossier.</p>

<ul><li>Il vous assiste pour prendre des mesures, si nécessaire, à dire d’Expert,</li>
<li>il vous accompagne pour mieux appréhender les documents techniques, juridiques et vos garanties contractuelles,</li>
<li>Il se met en relation avec l’Expert d’assurance, </li>
<li>il vous représente dans toutes les opérations d’expertise, de la contre-expertise jusqu’à l’accord d’indemnisation,</li>
<li>il participe à la réunion de contre-expertise, en présence de l’Expert de la partie adverse (assurance, DO, ou de partie) et expose ses arguments, dans une perspective de conciliation en amiable</li>
<li>il vous oriente et vous assiste dans la détermination des travaux de réparation et/ou confortement,</li>
<li>Il estime le coût des travaux.</li></ul>

<p>Afin de défendre au mieux vos intérêts, faites-vous accompagner par un Expert d’assuré</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert d’assuré</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/expert-assure/' className='active'>L'expert d'assuré</a></li><li><a href='/expertise-assure/expert-assure/role/' >Role</a></li><li><a href='/expertise-assure/expert-assure/objectifs/' >Objectifs</a></li><li><a href='/expertise-assure/expert-assure/mission/' >Déroulement de la mission</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details